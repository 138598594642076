<template>
  <div>
    <div class="typeArea" id="print-content">
      <!-- 页顶 -->
      <ResumReportHeade report="就业分析报告" />
      <!-- 页顶 -->

      <!-- 基础信息 -->
      <div class="information" v-if="userInfo">
        <div class="informationTop">
          <span class="mationImg"
            ><img src="@/assets/image/resume/icon.png" alt=""
          /></span>
          <span class="mationText">基础信息</span>
        </div>

        <div class="informationCentre">
          <div class="CentreMessage">
            <div class="MessageTop">
              <span class="name">{{ userInfo.data.nickname }}</span>
              <span class="gender">
                {{
                  userInfo.data.gender == 1
                    ? '男'
                    : userInfo.data.gender == 2
                    ? '女'
                    : '未知'
                }}
              </span>
              <span class="age">22岁</span>
              <span class="education">
                {{
                  userInfo.data.education === 1
                    ? '专科'
                    : userInfo.data.education === 2
                    ? '本科'
                    : userInfo.data.education === 3
                    ? '硕士'
                    : '博士'
                }}
              </span>
              <span class="work">应届生</span>
            </div>
            <div class="region">
              <span class="landmark"
                ><img src="@/assets/image/analysis/地点icon.png" alt=""
              /></span>
              {{ userInfo.data.city_name || '未知' }}
            </div>
          </div>
          <div class="CenterBottom">在读 {{ userInfo.data.school || '-' }}</div>
        </div>

        <div class="informationBottom">
          <div class="BottomTop">
            <div class="AnalyticalMajor">
              <span>分析专业：</span>
              <span class="manager">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.major_name
                    : '--'
                }}
              </span>
            </div>
            <div class="SpecialistRanking">
              <span>专业排名：</span>
              <span class="Industry">{{
                callBackObj.major_data_n
                  ? callBackObj.major_data_n.class_name
                  : ''
              }}</span>
              <span class="One"
                >第{{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.rank
                    : '--'
                }}名</span
              >
            </div>
          </div>
          <div class="BootomCenten">
            <div class="pay">
              <div class="AverageSalary">全国平均薪资</div>
              <div class="money">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.avg_salary_format[0]
                    : '--'
                }}k
              </div>
            </div>
            <div class="pay two">
              <div class="AverageSalary">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.recruitment_amount.this_year.year
                    : '--万'
                }}年招聘量
              </div>
              <div class="money">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.recruitment_amount.this_year
                        .formatNum[0]
                    : '--'
                }}万
              </div>
            </div>
            <div class="pay">
              <div class="AverageSalary">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.recruitment_amount.last_year.year
                    : ''
                }}年招聘量
              </div>
              <div class="money">
                {{
                  callBackObj.major_data_n
                    ? callBackObj.major_data_n.recruitment_amount.last_year
                        .formatNum[0]
                    : ''
                }}万
              </div>
            </div>
          </div>
          <div
            class="Bootome"
            :style="{
              color: callBackObj.major_data_n
                ? callBackObj.major_data_n.trend_percent > 0
                  ? 'rgb(63, 169, 63)'
                  : '#F93E39'
                : ''
            }"
          >
            <div>
              较上一年{{
                callBackObj.major_data_n
                  ? callBackObj.major_data_n.trend_percent > 0
                    ? '上涨'
                    : '下降'
                  : ''
              }}
              {{
                callBackObj.major_data_n
                  ? Math.abs(callBackObj.major_data_n.trend_percent)
                  : ''
              }}%
            </div>
          </div>
        </div>
      </div>
      <!-- 基础信息 -->

      <!-- 就业方向 -->
      <div class="employment" v-if="callBackObj.major_data_n">
        <div class="informationTop">
          <span class="mationImg"
            ><img src="@/assets/image/resume/icon.png" alt=""
          /></span>
          <span class="mationText">就业方向</span>
        </div>
        <div class="employmentTop">
          <span class="TopText">{{ majorObj.fixed_description }}</span>
          {{ majorObj.stress_description }}
        </div>
        <div class="employmentCenten">
          <div class="CentenTitle">
            <span class="ImgBooks"
              ><img src="@/assets/image/analysis/椭圆 .png" alt=""
            /></span>
            该专业可从事职位有
            <span>{{
              (callBackObj.major_data_n &&
                callBackObj.major_data_n.positions_work_on.length) ||
              '0'
            }}</span>
            个, 如下：
          </div>
          <div class="CentenBoxlist">
            <div
              v-if="callBackObj.major_data_n.positions_work_on.length"
              class="CentenBoxlist"
            >
              <div
                class="BoxBtn"
                v-for="(item, index) in callBackObj.major_data_n
                  .positions_work_on"
                :key="index"
              >
                {{ item.position }}
              </div>
            </div>
            <div v-else class="BoxBtn">暂无数据</div>
          </div>
        </div>
        <div class="employmentBootom">
          <div class="BootomTitle">该专业你需要掌握的技能有哪些？</div>
          <div v-if="callBackObj.major_data_n.need_skill.length">
            <div
              class="BootomListtext"
              v-for="(item, index) in callBackObj.major_data_n.need_skill"
              :key="index"
            >
              <span class="cancel"
                ><img src="@/assets/image/analysis/勾.png" alt=""
              /></span>
              {{ item }}
            </div>
          </div>
          <div v-else class="BootomListtext">暂无数据</div>
        </div>
      </div>
      <!-- 就业方向 -->

      <!-- 毕业就业地区 -->
      <div class="GraduateEmploymentArea question-table">
        <div class="informationTop">
          <span class="mationImg"
            ><img src="@/assets/image/resume/icon.png" alt=""
          /></span>
          <span class="mationText">毕业就业地区</span>
        </div>
        <div class="GraduateToptext">
          该专业在
          <span>{{
            callBackObj.major_data_n &&
            callBackObj.major_data_n.city_distribution[0].city
          }}</span>
          更受欢迎，招聘职位达到
          <span
            >{{
              callBackObj.major_data_n &&
              callBackObj.major_data_n.city_distribution[0].position_num
            }}个</span
          >
          , 占总体就业分布的{{
            callBackObj.major_data_n &&
            callBackObj.major_data_n.city_distribution[0].percent
          }}
        </div>
        <!-- 表格区域 -->
        <div
          class="GraduateTable"
          v-if="
            callBackObj.major_data_n &&
            callBackObj.major_data_n.city_distribution.length
          "
        >
          <table class="ListTable">
            <tr>
              <th>排名</th>
              <th style="width: 25%">地区</th>
              <th style="width: 25%">职位量</th>
              <th style="width: 30%">占比</th>
            </tr>
            <tr
              v-for="(
                item, index
              ) in callBackObj.major_data_n.city_distribution.slice(0, 5)"
              :key="index"
            >
              <td height="50px" style="color: #383a4d">{{ item.rank }}</td>
              <td height="50px" class="TdImg">
                {{ item.city }}
              </td>
              <td height="50px">
                {{ item.position_num }}
              </td>
              <td height="50px">
                {{ item.percent }}
              </td>
            </tr>
          </table>

          <table class="ListTable">
            <tr
              v-for="(
                item, index
              ) in callBackObj.major_data_n.city_distribution.slice(-5)"
              :key="index"
            >
              <td height="50px" style="color: #383a4d; width: 25%">
                {{ item.rank }}
              </td>
              <td height="50px" class="TdImg">
                {{ item.city }}
              </td>
              <td height="50px">
                {{ item.position_num }}
              </td>
              <td height="50px">
                {{ item.percent }}
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="elseImg">
          <img src="@/assets/image/no-data-icon1.png" alt="" />
          <div
            style="
              position: relative;
              left: 165px;
              bottom: 130px;
              font-size: 20px;
            "
          >
            暂无数据
          </div>
        </div>
      </div>
      <!-- 毕业就业地区 -->

      <!-- 第一页结尾 -->
      <ResumReportFoot :pages="`1/2`" class="question-table" />
      <!-- 第一页结尾 -->

      <!-- 第二页 -->
      <EmploymentProspectAnalysisTwo
        class="question-table"
        :industryList="industryList"
        :salary="salary"
        :education="education"
        :targetDegree="targetDegree"
        v-if="isOK"
      />
    </div>

    <el-button class="acticedBtn" @click="printFn" type="primary"
      >一键导出</el-button
    >
  </div>
</template>

<script>
import PdfLoader from '@/utils/htmlpdf.js'
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
import ResumReportFoot from '@/components/ResumeReportFoot' //诊断报告的脚部组件
import EmploymentProspectAnalysisTwo from './EmploymentProspectAnalysisTwo'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'EmploymentProspectAnalysi', //专业就业前景分析页码
  components: {
    ResumReportHeade,
    ResumReportFoot,
    EmploymentProspectAnalysisTwo
  },
  data() {
    return {
      isOK: false, //控制子组件的渲染
      industryList: [], //毕业就业行业数据
      salary: {}, //薪资待遇数据
      education: {}, // 招聘学历要求数据
      callBackObj: {}, // 专业就业报告内容数据
      targetDegree: {}, //历年招聘职位量变化数据
      majorObj: {
        fixed_description: null, // 就业方向固定描述
        stress_description: null // 就业方向强调描述
      },
      tableData: [
        {
          index: '1',
          region: '上海',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '2',
          region: '深圳',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '3',
          region: '广州',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '4',
          region: '北京',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '5',
          region: '济南',
          NumberOfJobs: '73.6k',
          make: '21%'
        }
      ],
      submitObj: {
        major_name: null, // 专业名称
        position_name: null, // 职位名称
        degree_index: null, // 学历索引
        degree_name: null, // 学历名称
        degree_type: null, // 学历类型
        experience_index: null, // 工作经验索引
        experience_name: null, // 工作经验名称
        experience_type: null, // 工作经验类型
        province_name: null, // 省名
        city_name: null // 城市名称
      }
    }
  },
  async mounted() {
    this.getUserInfo() //获取用户信息
    await this.getEmploymentReportFn()
    this.dataProcessingFn()
  },
  methods: {
    ...mapActions(['employmentReport', 'getUserInfo']),
    //获取专业就业报告内容
    async getEmploymentReportFn() {
      // 获取专业就业报告内容
      this.callBackObj = await this.employmentReport(this.submitObj)
    },
    //数据处理
    dataProcessingFn() {
      if (this.callBackObj.major_data_n.job_directions_description) {
        this.majorObj.fixed_description =
          this.callBackObj.major_data_n.job_directions_description.slice(0, 8)
        this.majorObj.stress_description =
          this.callBackObj.major_data_n.job_directions_description.substr(
            8,
            this.callBackObj.major_data_n.job_directions_description.length
          )
      }
      if (this.callBackObj.major_data_n.industry_distribution.length) {
        this.industryList = this.callBackObj.major_data_n.industry_distribution
      } else {
        this.industryList = []
      }

      this.salary = {
        position_name: this.callBackObj.position_data_n.position_name
          ? this.callBackObj.position_data_n.position_name
          : '',
        month_salary: this.callBackObj.position_data_n.month_salary
          ? this.callBackObj.position_data_n.month_salary
          : '',
        income_description: this.callBackObj.position_data_n.income_description
          ? this.callBackObj.position_data_n.income_description
          : '',
        description: this.callBackObj.position_data_n.description
          ? this.callBackObj.position_data_n.description
          : '',
        salary_rang: this.callBackObj.position_data_n.salary_range
          ? this.callBackObj.position_data_n.salary_range
          : '',
        city: this.callBackObj.position_data_n.city
          ? this.callBackObj.position_data_n.city
          : ''
      }

      this.education = {
        positionDataCity: this.callBackObj.position_data_n.city
          ? this.callBackObj.position_data_n.city
          : '',
        position_name: this.callBackObj.position_data_n.position_name
          ? this.callBackObj.position_data_n.position_name
          : '',
        degree: this.callBackObj.position_data_n.degree_requirements.length
          ? this.callBackObj.position_data_n.degree_requirements[0].degree
          : '',
        percent: this.callBackObj.position_data_n.degree_requirements.length
          ? this.callBackObj.position_data_n.degree_requirements[0].percent
          : '',
        requirementsList: this.callBackObj.position_data_n.degree_requirements
      }

      this.targetDegree = this.callBackObj.position_data_n
        .career_prospects_trend_years.length
        ? this.callBackObj.position_data_n.career_prospects_trend_years
        : ''
      this.isOK = true
    },
    printFn() {
      const pdf = document.getElementById('print-content') // 需要导出部分页面的id名
      this.pdfDownLoader = new PdfLoader(pdf, 'fileName', 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      this.pdfDownLoader.outPutPdfFn('就业分析报告')
    }
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style scoped lang="stylus">
.typeArea
  box-sizing border-box
  width 1240px
  margin 0 auto
  padding 0px 61px 0 61px
  height auto
  .information
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .informationCentre
      margin-left 22px
      .CentreMessage
        display flex
        justify-content space-between
        align-items center
        .MessageTop
          .name
            font-size 30px
            font-weight bold
            color #383A4D
          .gender
            font-size 19px
            font-weight 400
            color #383A4D
            margin-right 17px
            margin-left 15px
          .age
            display inline-block
            font-size 19px
            font-weight 400
            color #383A4D
            padding 0 17px
            height 12px
            position relative
          .age:before
            content ''
            width 2px
            height 100%
            background #383A4D
            position absolute
            left 0
            top 90%
            opacity 0.5
          .age:after
            content ''
            width 2px
            height 100%
            background #383A4D
            position absolute
            right 2px
            top 90%
            opacity 0.5
          .education
            display inline-block
            font-size 19px
            font-weight 400
            color #383A4D
            position relative
            height 12px
            padding 0 17px 0 17px
          .education:after
            content ''
            width 2px
            height 100%
            background #383A4D
            position absolute
            right 1px
            top 90%
            opacity 0.5
          .work
            font-size 19px
            font-weight 400
            color #383A4D
            margin-left 17px
        .region
          font-size 19px
          display flex
          align-items center
          .landmark
            margin-right 5px
            width 14px
            height 17px
      .CenterBottom
        margin-top 5px
        font-size 19px
    .informationBottom
      margin 34px 0 0 22px
      .BottomTop
        display flex
        align-items center
        font-size 19px
        .AnalyticalMajor
          margin-right 39px
          color #878A99
          .manager
            box-sizing border-box
            display inline-block
            border 1px solid #008AFF
            padding 3px 7px
            border-radius 3px
            color #008AFF
        .SpecialistRanking
          color #878A99
          .Industry
            color #383A4D
          .One
            box-sizing border-box
            display inline-block
            border 1px solid #008AFF
            padding 3px 7px
            border-radius 3px
            color #008AFF
            margin-left 5px
      .BootomCenten
        height 96px
        background #F1F3F7
        border-radius 10px
        margin-top 19px
        display flex
        justify-content space-evenly
        padding 17px 0 17px 0
        .pay
          display flex
          flex-direction column
          align-items center
          justify-content center
          .AverageSalary
            font-size 19px
            color #878A99
          .money
            font-size 29px
            font-weight 500
            color #383A4D
        .pay.two
          border-left 1px solid #CED4DE
          border-right 1px solid #CED4DE
          padding 0 113px 0 113px
      .Bootome
        margin-top 14px
        text-align center
        font-size 19px
        font-weight 500
        color #F93E39
  .employment
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .employmentTop
      font-size 19px
      font-weight 400
      color #383A4D
      .TopText
        color #878A99
    .employmentCenten
      height auto
      background #FFFFFF
      border 1px solid rgba(135, 138, 153, 0.4)
      border-radius 9px
      margin-top 25px
      padding 20px 54px 0 20px
      margin-bottom 20px
      .CentenTitle
        font-size 19px
        display flex
        align-items center
        .ImgBooks
          width 20px
          height 20px
          margin-right 5px
        span
          color #008AFF
      .CentenBoxlist
        display flex
        flex-wrap wrap
        margin-top 20px
        .BoxBtn
          background rgb(224, 241, 255)
          border-radius 3px
          font-size 16px
          color rgb(111, 188, 255)
          padding 9px 18px
          margin-right 15px
          margin-bottom 15px
    .employmentBootom
      .BootomTitle
        font-size 16px
        font-weight bold
        color #383A4D
      .BootomListtext
        font-size 19px
        font-weight 400
        color #383A4D
        border-bottom 1px dashed #383A4D
        padding-bottom 13px
        padding-top 14px
        line-height 29px
        display flex
        align-items center
        .cancel
          img
            display inline-block
            width 19px
            height 19px
            margin-right 5px
  .GraduateEmploymentArea
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .GraduateToptext
      font-size 19px
      font-weight 400
      color #383A4D
      & span
        display inline-block
        border 1px solid #008aff
        padding 3px 7px
        border-radius 3px
        color #008aff
    .GraduateTable
      display flex
      justify-content space-between
      margin-top 20px
      .ListTable
        width 500px
        height auto
        border-spacing 0
        border-radius 9px
        border solid 1px #dfdfdf
        overflow hidden
        th, td
          text-align center
          border 1px solid rgb(233, 234, 238)
          word-wrap break-word
        tr th
          background-color #eee
          height 50px
          font-size 16px
          font-weight 500
          color #9899AA
    .elseImg
      position relative
      width 400px
      height 360px
.acticedBtn
  position fixed
  top 50%
  right 0
</style>
