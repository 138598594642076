<template>
  <div class="EmploymentProspectAnalysisTwo">
    <ResumReportHeade :height="40" report="就业分析报告" />

    <!-- 毕业就业行业 -->
    <div class="graduate">
      <div class="informationTop">
        <span class="mationImg"
          ><img src="@/assets/image/resume/icon.png" alt=""
        /></span>
        <span class="mationText">毕业就业行业</span>
      </div>
      <div class="GraduateToptext">
        该专业更多从事
        <span>{{ this.industryList[0].industry || '--' }}</span>
        , 招聘职位达到
        <span>{{ this.industryList[0].position_num || '--' }}</span>
        , 占总体就业分布的{{ this.industryList[0].percent || '--' }}
      </div>
      <!-- 表格区域 -->
      <div class="GraduateTable" v-if="industryList.length">
        <table class="ListTable">
          <tr>
            <th>排名</th>
            <th style="width: 25%">行业名称</th>
            <th style="width: 25%">职位量</th>
            <th style="width: 30%">占比</th>
          </tr>
          <tr v-for="(item, index) in industryList.slice(0, 5)" :key="index">
            <td height="50px" style="color: #383a4d">{{ item.rank }}</td>
            <td height="50px" class="TdImg" style="width: 30%">
              {{ item.industry }}
            </td>
            <td height="50px">
              {{ item.position_num }}
            </td>
            <td height="50px">
              {{ item.percent }}
            </td>
          </tr>
        </table>

        <table class="ListTable">
          <tr v-for="(item, index) in industryList.slice(-5)" :key="index">
            <td height="50px" style="color: #383a4d; width: 25%">
              {{ item.rank }}
            </td>
            <td height="50px" class="TdImg" style="width: 35%">
              {{ item.industry }}
            </td>
            <td height="50px">
              {{ item.position_num }}
            </td>
            <td height="50px">
              {{ item.percent }}
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="elseImg">
        <img src="@/assets/image/no-data-icon1.png" alt="" />
        <div
          style="
            position: absolute;
            left: 165px;
            bottom: 130px;
            font-size: 20px;
          "
        >
          暂无数据
        </div>
      </div>
    </div>
    <!-- 毕业就业行业 -->

    <!-- 薪资待遇 -->
    <div class="salary">
      <div class="informationTop">
        <span class="mationImg"
          ><img src="@/assets/image/resume/icon.png" alt=""
        /></span>
        <span class="mationText">薪资待遇</span>
      </div>
      <div class="salaryTopTitle">
        {{ this.salary.city || '--' }}
        <span>{{ this.salary.position_name || '--' }}</span>
        平均月薪在
        <span>{{ this.salary.month_salary || '--' }}/月</span>
      </div>
      <div class="salaryCenten">
        <div class="salaryCentenLeft" v-if="salary.income_description">
          <div class="LeftText">
            <span>{{ this.salary.income_description.slice(0, 2) }}</span>
            {{ this.income_descriptionTitle }}
          </div>
          <div class="introduce">
            {{ income_descriptionText }}
          </div>
        </div>
        <div v-else class="salaryCentenLeft">
          <img
            src="@/assets/image/no-data-icon1.png"
            alt=""
            style="object-fit: contain"
          />
          <div
            style="
              position: relative;
              left: 148px;
              bottom: 73px;
              font-size: 20px;
            "
          >
            暂无数据
          </div>
        </div>
        <div class="salaryCentenRigth">
          <div
            v-if="this.salary.salary_rang.length"
            class="ImgVisual"
            style="width: auto; height: 220px"
            ref="chartHistogram"
          ></div>
          <div v-else class="ImgVisual" style="width: auto; height: 220px">
            <img
              src="@/assets/image/no-data-icon1.png"
              alt=""
              style="object-fit: contain"
            />
            <div
              style="
                position: relative;
                left: 264px;
                bottom: 75px;
                font-size: 20px;
              "
            >
              暂无数据
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="salaryBootom">
        {{ this.salary.income_description.replace('说明', '备注') }}
      </div>
    </div>
    <!-- 薪资待遇 -->

    <!-- 招聘学历要求 -->
    <div class="Employment">
      <div class="informationTop">
        <span class="mationImg"
          ><img src="@/assets/image/resume/icon.png" alt=""
        /></span>
        <span class="mationText">招聘学历要求</span>
      </div>
      <div class="EmploymentTopText">
        <span class="NanNIng">{{
          this.education.positionDataCity || '--'
        }}</span>
        企业在招聘
        <span class="Boder">{{ this.education.position_name || '--' }}</span>
        时倾向于
        <span class="Boder">{{ this.education.degree || '--' }}以上</span>
        , 占比达到{{ this.education.percent || '--' }}
      </div>
      <div class="EmploymentBottom">
        <div class="EmploymentLeft" v-if="distribution.length">
          <div
            class="EmploymenPie"
            style="width: 420px; height: 320px"
            ref="EmploymenPie"
          ></div>
        </div>
        <div
          v-else
          class="EmploymentTopText"
          style="width: 420px; height: 320px; position: relative"
        >
          <img src="@/assets/image/no-data-icon1.png" alt="" />
          <div
            style="
              position: relative;
              left: 183px;
              bottom: 104px;
              font-size: 20px;
            "
          >
            暂无数据
          </div>
        </div>
        <div class="EmploymentRigth" v-if="targetDegree.length">
          <div
            class="EmploymenDiscounting"
            style="width: auto; height: 320px"
            ref="EmploymenDiscounting"
          ></div>
        </div>
        <div
          v-else
          class="EmploymentTopText"
          style="width: 420px; height: 320px; position: relative"
        >
          <img src="@/assets/image/no-data-icon1.png" alt="" />
          <div
            style="
              position: relative;
              left: 183px;
              bottom: 102px;
              font-size: 20px;
            "
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>

    <!-- 招聘学历要求 -->

    <ResumReportFoot :pages="`2/2`" />
  </div>
</template>

<script>
require('echarts/lib/component/legend')
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
import ResumReportFoot from '@/components/ResumeReportFoot' //诊断报告的脚部组件

export default {
  components: {
    ResumReportHeade,
    ResumReportFoot
  },
  props: {
    //毕业就业行业表格数据
    industryList: {
      tyepe: Object,
      default: {}
    },
    // 薪资待遇数据
    salary: {
      tyepe: Object,
      default: {}
    },
    // 招聘学历要求数据
    education: {
      tyepe: Object,
      default: {}
    },
    //历年招聘职位量变化数据
    targetDegree: {
      tyepe: Object,
      default: {}
    }
  },
  data() {
    return {
      income_descriptionTitle: '', //薪资待遇说明的标题文字
      income_descriptionText: '', //薪资待遇说明的内容
      salary_rangeX: [], //薪资待遇柱状图x轴数据
      percentY: [], //薪资待遇柱状图数据
      distribution: [], //学历分布图数据
      targetDegreeX: [], //历年招聘职位量变化X轴数据
      targetDegreeY: [], //历年招聘职位量变化y轴暂定数据
      targetDegreey: [], //历年招聘职位量变化y轴动态数据
      tableData: [
        {
          index: '1',
          region: '建筑/建材/工程',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '2',
          region: '计算机软件',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '3',
          region: '房地产',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '4',
          region: '新能源',
          NumberOfJobs: '73.6k',
          make: '21%'
        },
        {
          index: '5',
          region: '互联网/电子商务',
          NumberOfJobs: '73.6k',
          make: '21%'
        }
      ],
      yAxisLabels: ['0k', '97k', '194k', '290k', '387k']
    }
  },
  async mounted() {
    await this.transition()
    if (this.salary.salary_rang.length) this.chartHistogram()
    if (this.education.requirementsList.length) this.EmploymenPie()
    if (this.targetDegree.length) this.EmploymenDiscounting()
  },
  methods: {
    //Echartsz柱状图图
    chartHistogram() {
      const chart = this.$echarts.init(this.$refs.chartHistogram)
      const option = {
        grid: {
          x: 40,
          y: 18,
          x2: 35,
          y2: 33
        },
        xAxis: {
          type: 'category',
          data: this.salary_rangeX,
          axisLine: {
            show: false // 隐藏x轴线
          },
          axisTick: {
            show: false // 隐藏x轴刻度线
          },
          axisLabel: {
            color: '#666' // 设置x轴标签颜色
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            data: this.percentY,
            barWidth: 25, // 设置柱子宽度
            itemStyle: {
              color: '#3e97d1'
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            // 实现数字展示在柱状图
            label: {
              show: true,
              formatter: '{c}%',
              position: 'top'
            }
          }
        ]
      }
      chart.setOption(option)
    },
    //Echartsz饼图
    EmploymenPie() {
      const chart = this.$echarts.init(this.$refs.EmploymenPie)
      const option = {
        title: {
          text: '学历分布图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: 0,
          itemHeight: 8,
          bottom: 85
        },
        color: [
          '#FFC600',
          '#FF7133',
          '#41C544',
          '#53CADD',
          '#008AFF',
          '#4B64FF',
          '#905DFF'
        ],
        series: [
          {
            name: '学历分布图',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [...this.distribution],
            itemStyle: {
              borderWidth: 5, //设置border的宽度有多大
              borderColor: '#fff' // 设置后饼图间会有白色空隙
            }
          }
        ]
      }
      chart.setOption(option)
    },
    //Echartsz折线图
    EmploymenDiscounting() {
      const chart = this.$echarts.init(this.$refs.EmploymenDiscounting)
      const option = {
        title: {
          text: '历年招聘职位量变化',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.targetDegreeX,
          axisTick: {
            alignWithLabel: true, // 将刻度线和刻度标签对齐
            inside: true // 将刻度线移到轴线内部
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true, // 显示分割线
            lineStyle: {
              color: '#ddd',
              width: 2,
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.targetDegreey,
            type: 'line',
            areaStyle: {
              color: 'rgba(135, 206, 235, 0.3)' // 设置淡蓝色
            }
          }
        ]
      }
      chart.setOption(option)
    },
    //处理父组件过来的数据
    transition() {
      if (this.salary.salary_rang && this.salary.salary_rang.length) {
        this.salary.salary_rang.forEach((item) => {
          this.salary_rangeX.push(item.salary_range)
          this.percentY.push(parseFloat(item.percent.replace('%', '')))
        })
      }
      if (this.salary.income_description) {
        this.income_descriptionTitle = this.salary.income_description.slice(
          this.salary.income_description.indexOf('：') + 1,
          this.salary.income_description.indexOf('？') + 1
        )
        this.income_descriptionText = this.salary.income_description.slice(
          this.salary.income_description.indexOf('？') + 1
        )
      }
      if (this.education.requirementsList.length) {
        this.education.requirementsList.forEach((item) => {
          item.percent = item.percent.replace('%', '')
          this.distribution.push({ value: item.percent, name: item.degree })
        })
      }
      if (this.targetDegree.length) {
        this.targetDegree.forEach((item) => {
          this.targetDegreeX.push(item.date)
          this.targetDegreeY.push(item.formatJobNum.toString())
        })
        this.targetDegreeY.forEach((item) => {
          if (item.includes('K')) {
            item = item.replace('K', '')
            item = Number(item)
            item = item.toFixed(3).replace('.', '')
          }
          Number(item)
          this.targetDegreey.push(item)
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.EmploymentProspectAnalysisTwo
  .graduate
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .GraduateToptext
      font-size 19px
      font-weight 400
      color #383A4D
      & span
        display inline-block
        border 1px solid #008aff
        padding 3px 7px
        border-radius 3px
        color #008aff
    .GraduateTable
      display flex
      justify-content space-between
      margin-top 20px
      .ListTable
        width 500px
        height auto
        border-spacing 0
        border-radius 9px
        border solid 1px #dfdfdf
        overflow hidden
        th, td
          text-align center
          border 1px solid rgb(233, 234, 238)
          word-wrap break-word
        tr th
          background-color #eee
          height 50px
          font-size 16px
          font-weight 500
          color #9899AA
    .elseImg
      position relative
      width 400px
      height 360px
  .salary
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .salaryTopTitle
      font-size 19px
      font-weight 400
      color #383A4D
      margin-left 24px
      span
        display inline-block
        border 1px solid #008aff
        padding 3px 7px
        border-radius 3px
        color #008aff
    .salaryCenten
      display flex
      margin-left 24px
      margin-top 25px
      justify-content space-between
      .salaryCentenLeft
        width 418px
        height 207px
        background #F1F3F7
        border-radius 5px
        padding 20px 20px 0 20px
        .LeftText
          font-size 16px
          font-weight bold
          color #383A4D
          margin-bottom 20px
          span
            display inline-block
            border 1px solid #008aff
            padding 3px 7px
            border-radius 3px
            color #008aff
            font-size 19px
        .introduce
          font-size 16px
          font-weight 400
          color #383A4D
          line-height 25px
      .salaryCentenRigth
        flex 1
    .salaryBootom
      margin-left 25px
      font-size 16px
      color #878A99
  .Employment
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 37px 19px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .EmploymentTopText
      font-size 19px
      color #383A4D
      margin-bottom 42px
      .NanNIng
        font-size 19px
        font-weight bold
      .Boder
        display inline-block
        border 1px solid #008aff
        padding 3px 7px
        border-radius 3px
        color #008aff
    .EmploymentBottom
      display flex
      justify-content space-between
      .EmploymentRigth
        margin-left 20px
        flex 1
</style>
